import React from 'react';


export default () => (
  <div style={{ width: '80%', margin: 'auto' }}>
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <h1 style={{ fontSize: 50, color: 'white' }}>
          403 - Erreur d'authentification
        </h1>
        <h2 style={{ color: 'white' }}>
          Erreur d'authentification au site de contenu.
        </h2>
        <h2 style={{ color: 'white' }}>
          Veuillez contacter les administrateurs du site.
        </h2>
    </div>
  </div>
);