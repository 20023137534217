const loadXHR = (url) => {
    return new Promise(function (resolve, reject) {
        try {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            /*  console.log("CONSOLE loadXHR", url); */

            xhr.responseType = "blob";
            xhr.onerror = function () { reject("Network error.") };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(xhr.response)
                } else {
                    reject("Loading error:" + xhr.statusText)
                }
            };
            xhr.send();
        }
        catch (err) { reject(err.message) }
    });
}

const convertMedia = (items) => {
    /* console.log("CONSOLE convertMedia", items); */
    return new Promise(function (resolve, reject) {

        let arr = []


        items.forEach((item, i) => {

            let media = {};


            media.file = `${item.media}`;

            switch (true) {
                case item.format === "png":
                    media.content_type = "image/png"
                    break;
                case !item.format:
                    switch (item.type) {
                        case "voices":
                            media.content_type = 'audio/mpeg'
                            break;
                        case "images":
                            media.content_type = 'image/jpeg'
                            break;
                        case "videos":
                            media.content_type = 'video/mp4'
                            break;
                        case "glb":
                            media.content_type = 'model/gltf-binary'
                            break;
                        case "gltf":
                            media.content_type = 'model/gltf+json'
                            break;
                    }
            }

            /* console.log("CONSOLE convertMedia media", media); */

            loadXHR(`/${item.folder}/${item.type}/${item.media}`)
                .then((blob) => {
                    media.data = blob;
                    arr.push(media)
                    if (items.length === arr.length) {
                        resolve(arr)
                    }
                })
        })
    })
}

export const blobReader = (blob) => {
    return new Promise(function (resolve, reject) {
        try {
            let reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result)
            };
            reader.readAsDataURL(blob);
        }
        catch (err) { reject(err.message) }
    })
}

export const createAttachments = async (db, id, arr) => {
    let doc = {
        "_id": id,
        "_attachments": {}
    };
    arr.forEach((media, i) => {
        doc["_attachments"][media.file] = {}
        doc["_attachments"][media.file].content_type = media.content_type
        doc["_attachments"][media.file].data = media.data
    })
    console.log('CONSOLE UTILZ createAttachments doc', doc)
    try {
        let result = await db.put(doc);
    } catch (err) {
        console.log(err);
    }
}

export const getAllQuestionsMedia = (questions) => {

    let arrMedias = []

    const pushItem = (item, type, folder, format) => {
        let ext;
        if (format) {
            ext = "." + format
        } else {
            ext =
                type === "videos" ? ".mp4" :
                    type === "voices" ? ".mp3" :
                        type === "images" ? ".jpg" :
                            type === "glb" ? ".glb" :
                                type === "gltf" ? ".gltf" : undefined
        }

        if (ext && item && item !== "") {
            let res = arrMedias.find(el => el.media === item + ext)
            if (!res) {
                arrMedias.push({
                    media: item + ext,
                    format: format,
                    type: type,
                    folder: folder
                });
            }
        }
    }

    return new Promise(function (resolve, reject) {
        questions.forEach((question, i) => {
            console.log("questions", question)
            let gameplay = question.answers && question.answers[0];
            let folder = "module" + question.activity.module.module_id;

            if (question.voice) {
                pushItem(question.voice, "voices", folder)
            }

            if (question.exercice.instruction && question.exercice.instruction.voice) {
                pushItem(question.exercice.instruction.voice, "voices", folder)
            }

            if (question.exercice.image) {
                pushItem(question.exercice.image, "images", folder)
            }

            if (question.image) {
                pushItem(question.image, "images", folder)
            }

            switch (gameplay.__typename) {
                case "ComponentGameplayDndDiag":
                    gameplay.characters.forEach(character => {
                        pushItem(character.text, "images", folder)
                    })
                    break;
                case "ComponentGameplayCharactersIntro":
                    gameplay.characters.forEach(character => {
                        pushItem(character.image, "images", folder)
                        pushItem(character.voice, "voices", folder)
                    })
                    break;
                case "ComponentGameplayQcm":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.options.forEach(option => {
                        pushItem(option.voice, "voices", folder)
                    })
                    break;
                case "ComponentGameplayQcmVideo":
                    pushItem(gameplay.video, "videos", folder)
                    gameplay.options.forEach(option => {
                        pushItem(option.voice, "voices", folder)
                    })
                    break;
                case "ComponentGameplayPointandclick":
                    pushItem(gameplay.image, "images", folder)
                    break;
                case "ComponentGameplayDiorama":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.carousel.forEach(item => {
                        pushItem(item.image, "images", folder)
                    })
                    break;
                case "ComponentGameplayQcmImage":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.options.forEach(option => {
                        pushItem(option.image, "images", folder)
                    })
                    break;
                case "ComponentGameplayDnD2D":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.choices.forEach(choice => {
                        pushItem(choice.image, "images", folder, choice.media_type)
                        pushItem(choice.image + '_true', "images", folder, choice.media_type)
                        pushItem(choice.image + '_false', "images", folder, choice.media_type)
                    })
                    break;
                case "ComponentGameplayDnD2Dxy":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.choices.forEach(choice => {
                        pushItem(choice.image, "images", folder, choice.media_type)
                        pushItem(choice.image + '_true', "images", folder, choice.media_type)
                        pushItem(choice.image + '_false', "images", folder, choice.media_type)
                    })
                    gameplay.presets.forEach(preset => {
                        pushItem(preset.image, "images", folder)
                    })
                    break;
                case "ComponentGameplayPointNcLick3D":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.choices.forEach(choice => {
                        pushItem(choice.image, "images", folder)
                        pushItem(choice.image + '_true', "images", folder)
                        pushItem(choice.image + '_false', "images", folder)
                    })
                    pushItem("scene_full", "glb", "3D");
                    pushItem("collisions-noground", "gltf", "3D");
                    let glbs = ['Adam', 'Assia', 'Daya', 'Juliette', 'Leonie', 'Li', 'Matthis', 'Tom', "Checked", "Closed"]
                    glbs.forEach(glb => pushItem(glb, "glb", "3D"))
                    break;
                case "ComponentGameplayDnD2DcLasse":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.choices.forEach(choice => {
                        pushItem(choice.image, "images", folder, choice.media_type)
                        pushItem(choice.image + '_true', "images", folder, choice.media_type)
                        pushItem(choice.image + '_false', "images", folder, choice.media_type)
                    })
                    break;
                case "ComponentGameplayDnD2DxyTwoPossibleAnswers":
                    pushItem(gameplay.image, "images", folder)
                    gameplay.choices.forEach(choice => {
                        pushItem(choice.image, "images", folder)
                        pushItem(choice.image + '_true', "images", folder)
                        pushItem(choice.image + '_false', "images", folder)
                    })
                    gameplay.presets.forEach(preset => {
                        pushItem(preset.image, "images", folder)
                    })
                    break;
                case "ComponentGameplayLabyAnswers":

                    pushItem("2REPEAT", "images", folder, "png")
                    pushItem("3REPEAT", "images", folder, "png")
                    pushItem("4REPEAT", "images", folder, "png")
                    pushItem("5REPEAT", "images", folder, "png")
                    pushItem("6REPEAT", "images", folder, "png")

                    pushItem("START", "images", folder, "png")
                    pushItem("UP", "images", folder, "png")
                    pushItem("LEFT", "images", folder, "png")
                    pushItem("RIGHT", "images", folder, "png")
                    pushItem("DOWN", "images", folder, "png")

                    pushItem("2REPEAT-WORD", "images", folder, "png")
                    pushItem("3REPEAT-WORD", "images", folder, "png")
                    pushItem("START-WORD", "images", folder, "png")
                    pushItem("UP-WORD", "images", folder, "png")
                    pushItem("DOWN-WORD", "images", folder, "png")
                    pushItem("LEFT-WORD", "images", folder, "png")
                    pushItem("RIGHT-WORD", "images", folder, "png")
                    pushItem("QUESTION-WORD", "images", folder, "png")

                    pushItem("ARROW-UP", "images", folder, "png")
                    pushItem("ARROW-DOWN", "images", folder, "png")
                    pushItem("ARROW-LEFT", "images", folder, "png")
                    pushItem("ARROW-RIGHT", "images", folder, "png")

                    pushItem("questionmark", "images", folder, "png")

                    pushItem(gameplay.image, "images", folder)

                    pushItem("leonie", "images", folder, "png")
                    pushItem("leonie-false", "images", folder)
                    pushItem("leonie-true", "images", folder)

                    pushItem("false", "images", folder)
                    pushItem("true", "images", folder)

                    pushItem("cross", "images", folder, "png")

                    break;
                case "ComponentGameplayExplore":
                case "ComponentGameplayPickupOrder":
                case "ComponentGameplayReach":
                case "ComponentGameplayQcm3DImage":
                case "ComponentGameplayQcm3DtExt":
                    pushItem("scene_full", "glb", "3D");
                    pushItem("collisions-noground", "gltf", "3D");
                    break;
            }

        });

        convertMedia(arrMedias)
            .then(data => {
                resolve(data)
            })

    })

};