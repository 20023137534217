import React, { useEffect, useState, useReducer, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import * as Sentry from '@sentry/browser';

// import Gameplay3DContainer from './containers/Gameplay3DContainer'; //SI_FAIL_STRAPI 

import introspectionQueryResultData from './fragmentTypes.json';

import useAuth from './hooks/useAuth';

import * as ROLES from './constants/roles';
import * as ROUTES from './constants/routes';

import StoreProvider, { StoreContext } from "./Store";
import Splash from './pages/Splash';
import ComingSoon from "./pages/ComingSoon.js";
import Dashboard from './pages/Dashboard';
import EducationalSpace from './pages/EducationalSpace';
/* import Activities from "./pages/Activities"; */
/* import Home from './pages/Home.js'; */

import Layout from "./components/Layout";
import Loading from './components/Loading';

import Error403 from './pages/Error403';
import Error403Strapi from './pages/Error403Strapi';
import Logout from './pages/Logout';

import 'antd/dist/antd.css';
import './scss/App.scss';


const App = () => {
  const Home = React.lazy(() => import('./pages/Home'));
  const Activities = React.lazy(() => import('./pages/Activities'));

  // const location = useLocation();
  // connection to strapi cms
  const { strapiAuth, serverAuth } = useAuth();

  const [error403Strapi, setError403Strapi] = useState(false);
  const [error403, setError403] = useState(false);
  const [loadingAuthStrapi, setLoadingAuthStrapi] = useState(true);
  const [jwt, setJwt] = useState();

  useEffect(() => {
    serverAuth()
      .then((isAuth) => {
        if (isAuth) {
          strapiAuth()
            .then((token) => {
              console.log('token strapi is : ', token);
              setJwt(token);
              setLoadingAuthStrapi(false);
            })
            .catch((error) => {
              Sentry.captureException(error);
              setError403Strapi(true);
              setLoadingAuthStrapi(false);
            });
        } else {
          setError403(true);
        }
      })
      .catch((error) => {
        setError403(true);
      });

    return () => console.log('AUTH unmounted');
  }, []);

  // strapi graphql configuration
  const request = (operation) => {
    operation.setContext({
      headers: {
        authorization: jwt ? `Bearer ${jwt}` : ''
      }
    })
  };

  const requestLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  );

  // strapi dynamic fields are unions types : https://strapi.io/documentation/3.0.0-beta.x/plugins/graphql.html#fetch-dynamic-zone-data
  // apollo-client needs a fragment matcher to be able to query fragment unions in graphql : https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
            Sentry.captureException(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          });
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          Sentry.captureException(`[Network error]: ${networkError}`);
        }
      }),
      requestLink,
      new HttpLink({
        uri: process.env.REACT_APP_STRAPI_GRAPHQL_ENDPOINT,
        credentials: 'same-origin'
      })
    ]),
    cache: new InMemoryCache({ fragmentMatcher })
  });

  if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
  }

  return console.log('process.env.PUBLIC_URL', process.env.PUBLIC_URL) || (
    <ApolloProvider client={client}>
      {/*  <PouchDB name="bordas2" revs_limit="1"> */}
      <StoreProvider>
        <StoreContext.Consumer>
          {({
            user: {
              user
            },
            error: {
              error
            },
            loading: {
              loading
            }
          }) => {
            if (error && error.auth || error403) {
              return <Error403 />
            } else if (error403Strapi) {
              return <Error403Strapi />
            } else if (error && error.strapi) {
              return <Loading />
            } else if (!Object.values(loading).every(item => !item) || loadingAuthStrapi) {
              return <Loading />
            } else {
              return (
                <Router>
                  {user && <Redirect to={`/${ROUTES.EAN13}/${ROUTES.SPLASH}`} />}
                  <Layout>
                    <Switch>
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.SPLASH}`} render={() => <Splash />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.LOGOUT}`} render={() => <Logout />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.HOME}`} render={() => <Suspense fallback={<Loading />}> <Home /> </Suspense>} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`} render={() => <Suspense fallback={<Loading />}> <Home /> </Suspense>} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.COMING_SOON}`} render={() => <ComingSoon />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.DASHBOARD}`} render={() => <Suspense fallback={<Loading />}> <Dashboard /> </Suspense>} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.ESPACE}`} render={() => <Suspense fallback={<Loading />}> <EducationalSpace /> </Suspense>} />
                      <Route path={`/${ROUTES.EAN13}/:moduleParam`} render={() => <Suspense fallback={<Loading />}> <Activities /> </Suspense>} />
                    </Switch>
                  </Layout>
                </Router>
              )
            }
          }}
        </StoreContext.Consumer>
      </StoreProvider>
      {/*  </PouchDB> */}
    </ApolloProvider>
  );
}

export default App;
