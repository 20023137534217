import React, { useContext } from 'react';

import * as ROLES from '../constants/roles';
import * as textConstant from '../constants/textContent';

import { StoreContext } from '../Store';

import useRecordRemote from '../hooks/useRecordRemote';

import NextButton from '../assets/images/next.png';
import Leonie2 from '../assets/images/leonie2.png';

import '../scss/Logout.scss';
import AudioGeneral from '../components/AudioGeneral';

import Save from '../assets/audio/sauvegarde.mp3';
import Bye from '../assets/audio/abientot.mp3';

export default () => {
  const {
    user: {
      user
    }
  } = useContext(StoreContext);

  const { sendData, isDataSynced, isTrackingSynced } = useRecordRemote(user.id);

  const doLogout = () => {
    // TODO : add prefixes + check why 5kb left whereas all dbs are gone
    // window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

    // Delete 3 localDBs
    const dbs = ["_pouch_bordas", "_pouch_history", "_pouch_trackings"];
    dbs.forEach((db, index) => {
      let deleteReq = window.indexedDB.deleteDatabase(db);

      deleteReq.onerror = function (event) {
        console.log("ERROR deleting " + db, event);
      }

      deleteReq.onsuccess = function () {
        console.log("SUCCESS deleting " + db);

        if (index === (dbs.length - 1)) {
          console.log("Last db deleted - SHOULD LOGT OUT");
          localStorage.clear();
          window.location.replace(process.env.REACT_APP_LOGOUT);
        }
      }
    });
  }

  console.log("isDataSynced && isTrackingSynced", isDataSynced, isTrackingSynced);

  return (
    <>
      {user.roles && user.roles.includes(ROLES.STUDENT) ?
        isDataSynced && isTrackingSynced ?

          <div className="logout-container centered">
            <div className="titles-container">
              <h1>{textConstant.sync_data_success}</h1>
              <AudioGeneral voice={Bye} ></AudioGeneral>
            </div>

            <div className="student-send-data">
              <button className={"btn"} onClick={() => doLogout()}>
                <img src={NextButton} className="next-button" alt="next-button" />
              </button>
              <img src={Leonie2} alt="leonie" />
            </div>
          </div>

          :

          <div className="logout-container centered">
            <div className="titles-container">
              <h1>{textConstant.sauvgarder_questions_title}</h1>
              <h3>{textConstant.sauvgarder_questions_text}</h3>
              <AudioGeneral voice={Save} ></AudioGeneral>
            </div>

            <div className="student-send-data">
              <button className={`btn ${isDataSynced && isTrackingSynced && "disabled"}`} onClick={() => sendData()}>
                <img src={NextButton} className="next-button" alt="next-button" />
              </button>
              <img src={Leonie2} alt="leonie" />
            </div>
          </div>

        : null}

      {user.roles && user.roles.includes(ROLES.TEACHER) ?
        <div className="logout-container centered">
          <div className="titles-container">
            <h2>{textConstant.quitter_prof}</h2>
          </div>

          <div className="prof-send-data">
            <button className="btn" onClick={() => doLogout()}>Oui</button>
            <button className="btn" onClick={() => window.history.back()}>Non</button>
          </div>
        </div>
        : null}

    </>
  )
}