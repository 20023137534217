// Module 2

export const EXPLORE = 'EXPLORE';
export const REACH = 'REACH';
export const ACT_AND_RETURN = 'ACT_AND_RETURN';
export const PICKUP_ORDER = 'PICKUP_ORDER';
export const PICKUP_FASTEST = 'PICKUP_FASTEST';

// Module 6

export const QCM3D_IMAGE = 'QCM3D_IMAGE';
export const QCM3D_TEXT = 'QCM3D_TEXT';
export const REACH_BLIND = 'REACH_BLIND';
export const REACH_TORCHLIGHT = 'REACH_TORCHLIGHT';