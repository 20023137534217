import gql from "graphql-tag";

export const GET_MODULES = gql`query{
  modules(sort:"module_id:asc"){
    id
    module_id
    name
    description
    activities(sort: "activity_id:asc") {
      id
      activity_id
      shuffle_questions
      valid_answers
      name
      exercices(sort: "exercice_id:asc") {
        id
        image
        exercice_id
        instruction {
          id
          text
        }
      }
    }
  }
}`;

export const GET_QUESTIONS = gql`query{
  questions(sort:"question_id:asc"){
    id
    key
    question_id
    text
    text_mobile
    voice
    image
    exercice{
      exercice_id
      id
      instruction {
        id
        text
        voice
      }
      image
    }
    activity {
      id
      activity_id
      shuffle_questions
      valid_answers
      module{
        id
        module_id
        activities {
          id
        }
      }
    }
    answers {
      ... on ComponentGameplayCharactersIntro {
        id
        image
        isCharacterFind
        characters {
          id
          name
          image
          voice
        }
      }
      ... on ComponentGameplayQcm {
        id
        image
        options {
          id
          value
          isTrue
          voice
        }
      }
      ... on ComponentGameplayQcmVideo {
        id
      video
      timecode_start
      timecode_end
      options{
          id
          value
          isTrue
          voice
        }
      }
      ... on ComponentGameplayPointandclick {
        id
        svg
        image
      }
      ... on ComponentGameplayDndDiag{
        id
        dndSchema
        sequences{
          text
        }
        characters{
          text
        }
      }
      ... on ComponentGameplayDiorama {
        id
        image
        carousel {
          id
          timer
          image
        }
        arrows {
          id
          svg
          isTrue
        }
      }
      ... on ComponentGameplayExplore {
        id
        cameraDirection {
          x
          y
          z
        }
        gameplay
        position {
          x
          y
          z
        }
        hasMapFeedback
      }
      ... on ComponentGameplayReach {
        id
        cameraDirection {
          x
          y
          z
        }
        gameplay
        position {
          x
          y
          z
        }
        hasMapFeedback
        target {
          name
          file_name
          coords {
            x
            y
            z
          }
        }
        objectsPickup {
          file_name
        }
      }
      ... on ComponentGameplayPickupOrder {
        id
        cameraDirection: cameraDirectionPickup {
          x
          y
          z
        }
        gameplay
        position: startPositionPickup {
          x
          y
          z
        }
        cube1_position
        cube2_position
        cube3_position
        cube4_position
        cube5_position
        position1 {
          x
          y
          z
        }
        position2 {
          x
          y
          z
        }
        position3 {
          x
          y
          z
        }
        position4 {
          x
          y
          z
        }
        position5 {
          x
          y
          z
        }
        hasMapFeedback
      }
      ... on ComponentGameplayQcmImage {
        id
        image
        options {
          id
          isTrue
          image
        }
      }
      ... on ComponentGameplayDnD2D {
        id
        image
        choices {
          id
          name
          image
          media_type
        }
        answers {
          id
          name
          image
          media_type
          location
        }
      }
      ... on ComponentGameplayDnD2Dxy {
        id
        image
        choices {
          id
          name
          image
        }
        answers {
          id
          name
          image
          coordinates
        }
        presets {
          id
          name
          image
          coordinates
        }
      }
      ... on ComponentGameplayPointNcLick3D {
        id
        cameraPosition
        lookAtPoint: controlsTarget
        choices {
          id
          name
          image
        }
        answers {
          id
          name
          coordinates
        }
      }
      ... on ComponentGameplayDnD2DcLasse {
        id
        image
        choices {
          id
          name
          image
          media_type
        }
        answers {
          id
          name
          image
          coordinates
        }
      }
      ... on ComponentGameplayDnD2DxyTwoPossibleAnswers {
        id
        image
        choices {
          id
          name
          image
        }
        answerOne {
          id
          name
          image
          coordinates
        }
        answerTwo {
          id
          name
          image
          coordinates
        }
        presets {
          id
          name
          image
          coordinates
        }
      }

      ... on ComponentGameplayQcm3DImage {
        id
        gameplay
        position: cameraPosition {
          x
          y
          z
        }
        cameraDirection {
          x
          y
          z
        }
        qcm3dImageElement {
          name
          correctAnswer
        }
        rotationAngleQuantity
        rotationDirection
      }
      ... on ComponentGameplayLabyAnswers {
        id
        Gameplay
        Path {
          directions
        }
        choice:choices
        prefilled
        canvasHeight
        canvasWidth
        startX
        startY
        image
        clickCellX
        clickCellY
      }
      ... on ComponentGameplayQcm3DtExt {
        id
        gameplay
        position: cameraPosition {
          x
          y
          z
        }
        cameraDirection {
          x
          y
          z
        }
        Qcm3DTextConsigne {
          Consigne
          Qcm3DTextChoices {
            value
            voice
            isTrue
            id
          }
        }
        rotationAngleQuantity
      }
    

    }
  }
}
`
