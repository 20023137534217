import React, { useRef, useEffect, useState } from 'react'
import '../scss/AudioGeneral.scss'
import * as utils from '../utils/mediaConverter';
import { useDB } from "react-pouchdb/browser";

function AudioGeneral({ voice, children }) {

    console.log("audioelement", voice)

    const [audio, setAudio] = useState(voice)


    const path = audio
    const generalAudioRef = useRef(null)

    const muteAudio = (elem) => {
        elem.muted = true;
        elem.pause();
    }

    const playAudio = (e) => {
        e.preventDefault()
        document.querySelectorAll("audio").forEach(elem => muteAudio(elem));
        generalAudioRef.current.muted = false;
        generalAudioRef.current.currentTime = 0;
        generalAudioRef.current.play();
    }


    return (
        audio ? <div className="general-audio-container" onClick={playAudio}>
            {children}
            <button className="btn"><span className="material-icons">volume_up</span></button>
            <audio ref={generalAudioRef} style={{ display: 'none' }} src={path}></audio>
        </div>
            : null
    )
}

export default AudioGeneral