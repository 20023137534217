import { useState } from 'react';
import useApi from "../hooks/useApi";
import useRecordLocal from "../hooks/useRecordLocal";

import { createStatement, sendStatement } from "../utils/tinCanTracking";
import { useDB } from "react-pouchdb/browser";
import { createDb, deleteDb } from "../utils/UtilzPouchDB";


function useRecordRemote(userId) {

  const { createHistory } = useApi();
  const { getAllHistory, getAllTrackings } = useRecordLocal(userId);

  const [isDataSynced, setIsDataSynced] = useState(false);
  const [isTrackingSynced, setIsTrackingSynced] = useState(false);

  const dbTrackings = useDB("trackings");


  const sendTracking = (statements) => {
    // Create statements and send to backend
    if (statements.length > 0) {
        statements.forEach(element => {
          //let documentId = userId + "_" + element.module + "_" + element.activity + "_" + element.question + '_' + element.questionId;
          let trackingStatement = createStatement(userId, element);
          sendStatement(trackingStatement, sendTrackingCallback);
        });
    }
    
    setIsTrackingSynced(true);

    // Empty trackings DB
    dbTrackings.allDocs().then(function (result) {
      return Promise.all(result.rows.map(function (row) {
        return dbTrackings.remove(row.id, row.value.rev);
      }));
    }).then(function () {
      console.log("dbTrackings empty")
    }).catch(function (err) {
      console.log("dbTrackings error", err)
    });
  }

  const sendHistory = (history) => {
    // Send history for chosen module to pers backend
    if (history.length > 0) {
      createHistory(localStorage.getItem('bordas-token'), JSON.stringify(history)).then((data) => {
      console.log("History sent", data);

      // Send feedback
      setIsDataSynced(true);
      });
    }
  }


  const sendData = () => {
    // Retrieve user trackings from localdb
    getAllTrackings().then((data) => {
      console.log(data)
      if (data) {
        let trackings = data.rows.filter(obj => obj.id.includes(userId) === true);
        console.log("Local TRACKINGS", trackings);

        // Create and send statements to LRS
        let statements = trackings.map(obj => obj.doc.data);
        sendTracking(statements);

        console.log("statements", statements);
      } else {
        console.log("No trackings recorded");
      }
    });

    // Send history to backend
    getAllHistory().then((res) => {
      if (res.data) {
        sendHistory(res.data);
      } else {
        // Send feedback
        setIsDataSynced(true);
      }
    });
  };


  function sendTrackingCallback(err, xhr) {
    if (err !== null) {
      if (xhr !== null) {
        console.log("Failed to save statement: " + xhr.responseText + " (" + xhr.status + ")");
        return;
      }
      console.log("Failed to save statement: " + err);
      console.log(xhr)
      return;
    }
    console.log("Statement saved");
  }


  return {
    sendData,
    isDataSynced,
    isTrackingSynced
  }
}
export default useRecordRemote;