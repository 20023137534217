import PouchDB from 'pouchdb-browser';

export const createDoc = async (db, id, data) => {
  try {
    let res = await db.put({
      _id: id,
      data: data
    });
    return res
  } catch (err) {
    console.log(err);
  }
}

export const getDoc = async (db, id) => {
  try {
      let res = await db.get(id);
      /* console.log("CONSOLE UTILZ getDoc", res); */
      return res
    } catch (err) {
        return err
    }
}

// Upsert function
export const createOrUpdateDoc = async (db, id, data) => {
  try {
    let doc = await db.get(id);
    let res = await db.put({
      _id: id,
      _rev: doc._rev,
      data: data
    });
    return res;
  } catch (err) {
    if (err.status === 404) {
      createDoc(db, id, data).then((data) => {
        console.log("Answers history localDB", data);
      })
    } else {
      console.log(err);
    }
  }
}

export const getAllDocs = async(db, id) => {
  try {
    let res = await db.allDocs({
      include_docs: true,
      startkey: id,
      endkey: false
    });
    return res
  } catch (err) {
      return err
  }
}

export const deleteDoc = async (db, id) => {
  try {
      let res = await db.remove(id);
      return res
    } catch (err) {
        return err
    }
}

export const updateDoc = async (db, id, update) => {
  try {
    var doc = await db.get(id);
    let newDoc = {
      _id: id,
      _rev: doc._rev,
      data: update
    }
    var response = await db.put(newDoc);
  } catch (err) {
    console.error('CONSOLE updateDoc ERROR', err);
  }
}

export const deleteDb = async (db) => {
  await db.destroy().then( res => {
  }).catch(function (err) {
    console.error("CONSOLE deleteDb", err);
  });
      
}

export const createDb = async(id) => {
  let db = await new PouchDB(id,{revs_limit: 1, auto_compaction:true})
  console.log("CONSOLE UTILZ createDb", db);
  return db
}

export const compactDb = async (db) => {
  try {
    var result = await db.compact();
    /* console.log("CONSOLE UTILZ compact", result); */
    
  } catch (err) {
    console.error("CONSOLE compactDb", err);
  }
}