import axios from 'axios';

function useApi() {

    const createHistory = async (token, userState) =>{
        let headers = { "Authorization": "Bearer " + token };
        let data = { "state": userState };

        try {
            const res = await axios.post(process.env.REACT_APP_PERS_URL+"/state", data, {headers: headers});
            return res;
        } catch(error) {
            return error;
        }
    }


    const readHistory = async (token) => {
        let headers = {
            "Authorization": "Bearer " + token
        };

        try {
            const res = await axios.get(process.env.REACT_APP_PERS_URL+"/state", {headers: headers});
            return res;
        } catch(error) {
            return error;
        }
    }

    const createTracking = async (token) => {
        console.log(token)
        // const history = {
        //     module: module,
        //     path: path,
        //     activity: activity,
        //     data: JSON.stringify(sequence)
        // }

        // let token = localStorage.getItem('hatier-token');
        // let authorization = "Bearer " + token;


        // try {
        //     // Send tracking data to EVB backend
        //     content.tracking = JSON.stringify(answers);
        //     const post = await api.storeTrackingTrackingPost(authorization, history)
        //     return post;
        // } catch (error) {
        //     return error;
        // }
    }


    const readTrackingAll = async (token) => {
        console.log(token)
        // let authorization = "Bearer " + token
        // try {
        //     const get = await api.obtainMultipleTrackingsSearchTrackingsGet(authorization)
        //     return get;
        // } catch(error){
        //     return error;
        // }
    }


    return {
        readHistory,
        createHistory,
        readTrackingAll,
        createTracking
    }

}

export default useApi;