import * as textConstant from "../constants/textContent";
import * as GAMEPLAYS from "../constants/gameplays3D";
import { isMobile } from "react-device-detect";

const errorHandler = (data) => {
  console.log('ERROR HANDLER', data)
  let message = '';
  let answer = '';
  switch (data.type) {
    case "ComponentGameplayQcmVideo":
    case "ComponentGameplayQcm":
      let correctAnswer = data.gameplay.options.find(el => el.isTrue)
      message = textConstant.feedback_msg_error_1;
      answer = correctAnswer.value;
      break;
    case "ComponentGameplayPointandclick":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_pNc;
      break;
    case "ComponentGameplayDiorama":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_diorama;
      break;
    case "Questions":
      message = textConstant.feedback_msg_error_2;
      break;
    case "ComponentGameplayReach":
      message = textConstant.feedback_msg_reach_error;
      break;
    case "ComponentGameplayPickupOrder":
      if (data.gameplay.gameplay === GAMEPLAYS.PICKUP_ORDER)
        message = textConstant.feedback_msg_pickup_order_error;
      else if (data.gameplay.gameplay === GAMEPLAYS.PICKUP_FASTEST)
        message = textConstant.feedback_msg_pickup_fastest_error;
      break;
    case "ComponentGameplayQcmImage":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_qcmImages;
      break;
    case "ComponentGameplayDnD2D":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_qcmImages;
      break;
    case "ComponentGameplayDnD2Dxy":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_qcmImages;
      break;
    case "ComponentGameplayPointNcLick3D":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_qcmImages;
      break;
    case "ComponentGameplayDnD2DcLasse":
      message = textConstant.feedback_msg_error_1;
      answer = textConstant.feedback_msg_qcmImages;
      break;
    case "ComponentGameplayDnD2DxyTwoPossibleAnswers":
      message = textConstant.feedback_msg_error_3;
      answer = textConstant.feedback_msg_qcmImages;
      break;
    case "ComponentGameplayQcm3DtExt":
      message = textConstant.feedback_msg_qcm3dtext_error;
      break;
    case "ComponentGameplayQcm3DImage":
      message = textConstant.feedback_msg_qcm3dimage_error;
      break;
    case "ComponentGameplayLabyAnswers":
      message = textConstant.feedback_msg_error_4;
      break;
  }
  return {
    message: message,
    isCorrect: false,
    answer: answer
  }
}


const successHandler = (data) => {
  console.log('SUCCESS HANDLER', data)

  let message = '';
  let answer = null;

  switch (data.type) {
    case "ComponentGameplayQcm":
    case "ComponentGameplayQcmVideo":
    case "ComponentGameplayPointandclick":
    case "ComponentGameplayDiorama":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayCharactersIntro":
      if (data.gameplay.isCharacterFind) {
        message = textConstant.feedback_msg_success
      } else {
        message = textConstant.feedback_msg_characters_intro
      }
      break;
    case "ComponentGameplayReach":
      if (data.gameplay.gameplay === "ACT_AND_RETURN") {
        message = textConstant.feedback_msg_act_and_return;
      }
      else {
        if (data.gameplay.hasMapFeedback) {
          message = isMobile ? textConstant.feedback_msg_reach_map_feedback_mobile
            : textConstant.feedback_msg_reach_map_feedback;
        }
        else {
          message = textConstant.feedback_msg_reach;
        }
      }
      break;
    case "ComponentGameplayExplore":
      message = isMobile
        ? textConstant.feedback_msg_feedback_explore_mobile
        : textConstant.feedback_msg_feedback_explore;
      break;
    case "ComponentGameplayPickupOrder":
      if (data.gameplay.gameplay === GAMEPLAYS.PICKUP_FASTEST) {
        message = textConstant.feedback_msg_pickup_fastest;
      }
      else {
        message = textConstant.feedback_msg_pickup_order;
      }
      break;
    case "ComponentGameplayQcmImage":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayDnD2D":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayDnD2Dxy":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayPointNcLick3D":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayDnD2DcLasse":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayDnD2DxyTwoPossibleAnswers":
      message = textConstant.feedback_msg_success;
      break;
    case "ComponentGameplayQcm3DtExt":
      message = textConstant.feedback_msg_qcm3dtext;
      break;
    case "ComponentGameplayQcm3DImage":
      message = textConstant.feedback_msg_qcm3dimage;
      break;
    case "ComponentGameplayLabyAnswers":
      message = textConstant.feedback_msg_success_2;
      break;
  }
  return {
    message: message,
    isCorrect: true,
    answer: answer
  }
}

const activityHandler = (data, answersValidity, isLastQuestionCorrect) => {

  let activityMessage, msg;

  if (data.activity.valid_answers === 0 || answersValidity.correct === data.activity.valid_answers) {

    msg = !data.isLastActivity ? textConstant.feedback_msg_activity_success_1 : textConstant.feedback_msg_activity_success_2

    if (isLastQuestionCorrect) {
      activityMessage = successHandler(data)
    } else {
      activityMessage = errorHandler(data)
    }

    activityMessage.activityEnd = msg;
    activityMessage.btnLabel = !data.isLastActivity ? textConstant.feedback_msg_next_activity : textConstant.feedback_msg_module_list

  } else {

    if (isLastQuestionCorrect) {
      activityMessage = successHandler(data)
    } else {
      activityMessage = errorHandler(data)
    }

    if (answersValidity.attempts === 0) {
      msg = textConstant.feedback_msg_improve_score
      activityMessage.restart = true
      activityMessage.btnLabel = textConstant.feedback_msg_restart_activity
    } else {
      msg = textConstant.feedback_msg_help
      activityMessage.restart = false
      activityMessage.btnLabel = !data.isLastActivity ? textConstant.feedback_msg_next_activity : textConstant.feedback_msg_module_list
    }

    activityMessage.activityEnd = msg;

  }

  return activityMessage

}

const diagnosticHandler = (payload) => {

  if (payload.isLastQuestion) {
    return {
      message: textConstant.feedback_msg_diag_end,
      isCorrect: payload.isCorrect,
      answer: null,
      btnLabel: textConstant.feedback_msg_module_list
    }
  } else {
    return {
      message: textConstant.feedback_msg_next_question,
      isCorrect: payload.isCorrect,
      answer: null
    }
  }
}

export const feedBackReducer = (state, action) => {
  let payload = action.payload;

  switch (action.type) {
    case "ERROR_QUESTION":
      return errorHandler(payload.data);
    case "SUCCESS_QUESTION":
      return successHandler(payload.data);
    case "END_ACTIVITY": {
      return activityHandler(payload.data, payload.answersValidity, payload.isLastQuestionCorrect);
    }
    case 'DIAGNOSTIC_FEEDBACK': {
      return diagnosticHandler(payload)
    }
    case "RESET_FEEDBACK":
      return {
        message: null,
        isCorrect: null,
        answer: null
      };
  }
};

export const activityValidation = (state, action) => {
  switch (action.type) {
    case "IS_CORRECT":
      return {
        ...state,
        correct: state.correct + 1
      };
    case "RESET_ANSWERS":
      return {
        correct: 0,
        attempts: 0
      };
    case "RESTART_ACTIVITY":
      return {
        correct: 0,
        attempts: state.attempts + 1
      };
  }
};
