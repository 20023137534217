import React, { useContext } from 'react';
import { Affix } from 'antd';
import MainNav from './Navigation';
import Footer from './Footer';
import * as textConstant from '../constants/textContent';

import logoBordas from '../assets/images/logo_bordas.png';
import evibLogo from '../assets/images/evidenceb_logo.jpg';

import "../scss/Layout.scss"
// import { StoreContext } from '../containers/Store';

import '../scss/Layout.scss';

const Logo = () => {

  return (
    <div id="logos">
      <div id="bordas">
        <img src={logoBordas} alt='logoBordas' />
      </div>
      <div id="evib">
        <img src={evibLogo} alt='evibLogo' />
      </div>
      {/* <div id="label-header">{textConstant.nav_label}</div> */}
    </div>
  );
};


const LayoutContainer = ({ children }) => {

  // const { user: { user: authUser } } = useContext(StoreContext);

  return (
    <>
      <div id="layout">
        <Affix>
          <div id="header">
            <Logo />
            <MainNav />
          </div>
        </Affix>

        <div id="content">
          <div className="">
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LayoutContainer;
