const TinCan = require('tincanjs');
let lrs;

try {
    lrs = new TinCan.LRS(
        {
            endpoint: process.env.REACT_APP_XAPI_URL,
            username: process.env.REACT_APP_XAPI_USER,
            password: process.env.REACT_APP_XAPI_PASSWORD,
            allowFail: false
        }
    );
} catch (ex) {
    console.log("Failed to setup LRS object: ", ex);
    // TODO: do something with error, can't communicate with LRS
}

export function createStatement(id, data) {
    console.log("DATA STATEMENT", data)
    let result = {
        "extensions": {
            "http://example.com/answer": data.answer,
            "http://example.com/module": data.module,
            "http://example.com/activity": data.activity,
            "http://example.com/question": data.question,
            "http://example.com/is_last_question": data.completionData.isLastQuestion,
            "http://example.com/is_last_activity": data.completionData.isLastActivity,
            "http://example.com/valid_answers_needed": data.completionData.validAnswersForActivity
        },
        "success": data.isCorrect
    }

    const statement = new TinCan.Statement(
        {
            "timestamp": data.tms,
            "actor": {
                "objectType": "Agent",
                "account": {
                    "name": id.toString() + "-CE1",
                    "homePage": process.env.REACT_APP_URL
                }
            },
            "verb": {
                "id": "http://adlnet.gov/expapi/verbs/answered",
                "display": {
                    "en-US": "answered"
                }
            },
            "object": {
                "id": process.env.REACT_APP_URL + window.location.pathname,
                "objectType": "Activity"
            },
            "result": result
        }       
    );
    console.log("TINCAN STATEMENT", statement)
    return statement
}

export function sendStatement(statement, callback) {
    lrs.saveStatement(
        statement,
        {
            callback: callback
        }
    );
}

export const getAllStatements = (id, callback) => {
    lrs.queryStatements({
      params: {
        agent: new TinCan.Agent({
          account: {
            name: id.toString() + "-CE1",
            homePage: process.env.REACT_APP_URL
          },
          objectType: "Agent"
        }),
        limit: 2147483647,
      },
      callback: callback
    })
}